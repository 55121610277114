import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit.
      Quas nesciunt hic excepturi culpa id nihil earum ex commodi?
      Tempore et inventore deserunt velit laboriosam nihil vel numquam debitis quis eum?
    </p>
  </Layout>
);

export default NotFoundPage;
